import { Header } from './Header';
import { CustomerCard } from './CustomerCard/CustomerCard';
import { CustomerCardHeader } from './CustomerCard/CustomerCardHeader';
import { CustomerCardBody } from './CustomerCard/CustomerCardBody';
import { useState, useEffect } from 'react';
import axios from 'axios';
import {
  HeaderContext,
  HeaderContextProps
} from '../../contexts/HeaderContext';
import { MdTextRotateUp, MdTextRotationDown } from 'react-icons/md';
import { useOktaAuth } from '../../components/OktaContext';

const getFilteredCustomers = (searchValue: string, customers: any[]) => {
  if (!searchValue) return customers;
  return customers.filter((customer) =>
    customer.name.toLowerCase().includes(searchValue.toLowerCase())
  );
};

export function ListContainer() {
  const [customerData, setCustomerData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const { authState } = useOktaAuth();

  const getCustomers = () => {
    authState?.accessToken?.accessToken
      ? axios
          .get(`${process.env.NEXT_PUBLIC_API_BASE}/v1/customers`, {
            headers: {
              Authorization: `Bearer ${authState.accessToken.accessToken}`
            }
          })
          .then((res) => {
            setCustomerData(res.data.body);
          })
          .catch((err) => console.error('customers err: ', err))
      : setCustomerData([]);
  };

  useEffect(() => {
    getCustomers();
    return () => setCustomerData([]);
  }, []);

  function sortAsc() {
    const sortedData = [...customerData].sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });
    setCustomerData(sortedData);
  }

  function sortDesc() {
    const sortedData = [...customerData].sort((a, b) => {
      return a.name > b.name ? -1 : 1;
    });
    setCustomerData(sortedData);
  }

  const contextValue: HeaderContextProps = {
    useSearch: true,
    setSearchValue,
    searchValue,
    useSort: true,
    sortItems: [
      {
        icon: MdTextRotateUp,
        label: 'Customer Name (A-Z)',
        onClick: sortAsc
      },
      {
        icon: MdTextRotationDown,
        label: 'Customer Name (Z-A)',
        onClick: sortDesc
      }
    ],
    useFilter: false,
    filterItems: []
  };

  const filteredCustomers = getFilteredCustomers(searchValue, customerData);

  const customerComponents = filteredCustomers.map((customer) => {
    return (
      <CustomerCard customerId={customer._id} key={customer._id}>
        <CustomerCardHeader customerName={customer.name}></CustomerCardHeader>
        <CustomerCardBody customerID={customer._id}></CustomerCardBody>
      </CustomerCard>
    );
  });

  return (
    <>
      <HeaderContext.Provider value={contextValue}>
        <Header title="Customers" />
      </HeaderContext.Provider>
      <div className="grid grid-cols-autofit gap-x-4 gap-y-2">
        {customerComponents}
      </div>
    </>
  );
}
