import { ReactNode } from 'react';
import { Card } from '@org-crowley/enterprise-react-component-library';
import NextLink from 'next/link';

export interface CustomerCardProps {
  children: ReactNode | string | ReactNode[];
  className?: string;
  customerId: string;
}

export function CustomerCard({ children, customerId }: CustomerCardProps) {
  return (
    <NextLink href={`/customer/${customerId}`}>
      <a>
        <Card className="mb-0 shadow-sm">{children}</Card>
      </a>
    </NextLink>
  );
}
