import { Typography } from '@org-crowley/enterprise-react-component-library';
import { HeaderActions } from './HeaderActions';
import cn from 'classnames';

export interface HeaderProps {
  title: string;
  subTitle?: string;
  showActions?: boolean;
  className?: string;
}

export function Header({ 
  title, 
  subTitle, 
  showActions = true, 
  className,
 }: HeaderProps) {
  
  return (
    <div
      className={cn(
        'flex',
        'flex-col',
        'pb-2',
        'sm:flex-row',
        'sm:justify-between',
        'md:pb-4',
        className
      )}
    >
      <div className="block sm:hidden">
        <Typography variant="h600" className="text-blue-80 mb-5" as="span">
          {title}
        </Typography>
        {subTitle && (
          <Typography
            variant="body-small"
            className="text-blue-80 pl-4"
            as="span"
          >
            {subTitle}
          </Typography>
        )}
      </div>
      <div className="hidden sm:block">
        <Typography variant="h700" className="text-blue-80" as="span">
          {title}
        </Typography>
        {subTitle && (
          <Typography
            variant="body-medium"
            className="text-blue-80 pl-4"
            as="span"
          >
            {subTitle}
          </Typography>
        )}
      </div>
      {showActions && <HeaderActions />}
    </div>
  );
}
